<template>
  <div v-tooltip="tooltip" style="display: inline-block">
    <div
      @click="$emit('sendUpdates')"
      class="Button1"
      :class="updated && !updating ? '' : 'Btn_disabled'"
      style="min-width: 12rem; padding-top: 0.75rem; padding-bottom: 0.75rem"
    >
      <div class="icon">
        <i :class="`fas fa-${isReferenceFinished ? 'save' : 'cloud-upload-alt'}`" style="font-size: 1.8rem"></i>
      </div>
      <span class="Btn_content">
        Salvează {{ isReferenceFinished ? "datele" : "draft" }}
        <i v-show="updating" class="fas fa-sync fa-spin"></i>
      </span>
    </div>
  </div>
</template>

<script>
const TIMER_SECONDS = 30;

function startTimer(d) {
  var timer = d,
    m,
    s;
  const f = () => {
    m = parseInt(timer / 60, 10);
    s = parseInt(timer % 60, 10);

    m = m < 10 ? "0" + m : m;
    s = s < 10 ? "0" + s : s;

    this.time = m + ":" + s;

    if (--timer < 0) {
      this.sendUpdates();
    }
  };
  f();
  return setInterval(f, 1000);
}

export default {
  props: {
    reloadCount: {
      type: Number,
      required: false,
      default: 0,
    },
    updated: {
      type: Boolean,
      required: false,
      default: false,
    },
    updating: {
      type: Boolean,
      required: false,
      default: false,
    },
    isReferenceFinished: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      interval: null,
      time: "",
    };
  },
  computed: {
    tooltip() {
      return this.updating
        ? "Modificările sunt în proces de salvare"
        : this.updated
        ? this.isReferenceFinished ? 'Salvați modificările recente' : `Până la următoarea salvare a mai rămas: ${this.time || "00:00"}`
        : "Nu exista modificari recente";
    },
  },
  watch: {
    updated(x) {
      if (x) this.start();
      else this.clear();
    },
    reloadCount() {
      this.start();
    },
  },
  methods: {
    start() {
      if (!this.updated) return;
      this.stop();
      if (this.isReferenceFinished) return;
      this.interval = startTimer.call(this, TIMER_SECONDS);
    },
    stop() {
      clearInterval(this.interval);
      this.interval = null;
    },
    clear() {
      this.stop();
      this.time = "";
    },
    sendUpdates() {
      this.clear();
      this.$emit("sendUpdates");
    },
  },
};
</script>