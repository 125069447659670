<template>
  <div class="ModalFlex">
    <div class="Popup_Inner_main">
      <div @click="close" class="Popup_close_btn">
        <img
          @load="imgLoaded"
          class="imgHide"
          src="../assets/media/vectors/close.svg"
          alt=""
        />
      </div>
      <div class="Popup_title">
        Versiuni referat
      </div>
      <div style="position: relative;">
        <transition-group name="popup">
          <h4
            key="0"
            v-if="!versions.length"
            class="prettyH4"
            style="color: #888;position: absolute;top: 0;left: 0;right: 0;"
          >
            <i class="fas fa-times"></i>
            Nu sunt versiuni
          </h4>
          <div
            key="1"
            v-else
            class="TBParent referenceStatusesBeautify"
            style="max-height: 60rem !important;"
          >
            <table>
              <thead>
                <th style="width: 4rem;">Nr.</th>
                <th>Nume Prenume / ID</th>
                <th>Instituție</th>
                <th>Departament</th>
                <th>Data creării/modificării</th>
                <th>Status referat</th>
                <th style="width: 15rem;">Acțiuni</th>
              </thead>
              <tbody>
                <tr v-for="vers in versions" :key="vers._version">
                  <td>{{ vers._version + 1 }}.</td>
                  <td>{{ vers.name }}</td>
                  <td>{{ vers.institution }}</td>
                  <td>{{ vers.department }}</td>
                  <td>{{ vers.modifyDate }}</td>
                  <td align="center">
                    <span
                      :data="vers.status"
                      v-tooltip="vers.statusBeautified"
                      >{{ vers.statusBeautified }}</span
                    >
                  </td>
                  <td>
                    <router-link :to="preparePath(vers._version)">
                      <div style="display: flex;align-items: center;">
                        <span>Deschide</span>
                        <i
                          style="margin-left: 1rem;"
                          class="fas fa-external-link-alt"
                        ></i>
                      </div>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </transition-group>
      </div>
    </div>
    <div class="Popup_footer">
      <div class="Popup_footer_col">
        <button @click="close" class="Popup_footer_btn Footer_btn1">
          Inapoi
        </button>
      </div>
      <div class="Popup_footer_col"></div>
    </div>
  </div>
</template>

<script>
import { REFERENCE_VERSIONS } from "@/api.js";

export default {
  props: {
    referenceId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      historyData: [],
      historyPath: "",
    };
  },
  computed: {
    validId() {
      return Number.isInteger(this.referenceId);
    },
    versions() {
      const data = [];
      const vers = this.historyData.map((e) => {
        data.push(e.data);
        return {
          name: e.createdBy.replace("(ID)=", ""),
          modifyDate: this.toDateAndTime(e.createdAt) || "",
          _version: e.versionNumber ?? this.makeid(6),
        };
      });

      const combined = this.combineObjects(
        data.map((e) => {
          if (e.status) {
            e.needReferateStatus = e.status;
            delete e.status;
          }
          return e;
        })
      );

      combined.forEach((e, i) => {
        vers[i]._data = e;
        vers[i].status = e.needReferateStatus || "-";
        vers[i].statusBeautified =
          {
            draft: "DRAFT",
            signature_waiting: "Așteptare semnare",
            cancelled: "Anulat",
            rejected: "Respins",
            approved: "Aprobat",
            locked: "În PAAP",
            completion: "Completare",
            completed: "Completat",
          }[e.needReferateStatus] || "-";
        vers[i].institution = e.institution?.name || "-";
        vers[i].department = e.department?.name || "-";
        vers[i]._id = e.id ?? null;
      });

      return vers.reverse();
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getHistoryData() {
      if (this.validId) {
        const error = (msg) => {
          this.$toastr.e(
            msg || "Obținerea istoricului a eșuat. Încercați mai tâziu."
          );
          this.close();
          this.setLoad();
        };

        this.setSafeLoad(12000);
        REFERENCE_VERSIONS(this.referenceId)
          .then((res) => {
            if (Array.isArray(res?.data?.result)) {
              this.historyData = res.data.result;
              this.setLoad();
            } else {
              error();
            }
          })
          .catch(error);
      }
    },
    preparePath(version) {
      if (!Number.isInteger(version) || !this.historyPath) return "#";

      return this.historyPath + (version + 1);
    },
  },
  watch: {
    $route() {
      this.setSafeLoad(2000);
      this.close();
    },
  },
  created() {
    this.getHistoryData();

    if (this.validId) {
      this.historyPath = `${this.$route.path}/${this.referenceId}/`;
    }
  },
};
</script>

<style lang="scss">
.referenceStatusesBeautify span[data] {
  display: inline-block;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  box-shadow: 0px 3px 6px rgba(75, 81, 91, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.15);
  cursor: default;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  max-width: 100%;

  &[data="draft"] {
    background-color: #c3c3c3;
  }
  &[data="signature_waiting"] {
    background-color: #ffc355;
  }

  &[data="completion"] {
    background-color: #f2994a;
  }

  &[data="completed"] {
    background-color: #569a36;
    color: #fff;
  }
  &[data="rejected"] {
    background-color: #ec4a4a;
  }
  &[data="cancelled"] {
    background-color: #ce302d;
    color: #fff;
  }
  &[data="approved"] {
    background-color: #569a36;
  }
  &[data="locked"] {
    background-color: #a94442;
    color: #fff;
  }
}
</style>
