<template>
  <div class="">
    <ul class="DeparmentDeleting_Parent" style="text-align: left">
      <template v-if="needReference">
        <li>
          Denumire: <b>{{ needReference.name }}</b>
        </li>
        <li>
          Anul referatului <b>{{ needReference.nryear }}</b>
        </li>
        <li>
          Data creării: <b>{{ toDate(needReference.createdAt) }}</b>
        </li>
        <li>
          Status: <b>{{ referenceStatus[needReference.needReferateStatus] }}</b>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
export default {
  props: { needReference: { type: Object, required: true } },
  data() {
    return {
      referenceStatus: {
        draft: "Draft",
        signature_waiting: "Așteptare semnare",
        rejected: "Respins",
        approved: "Aprobat",
        completion: "Trimis spre completare",
        completed: "Completat",
      },
    };
  },
};
</script>

<style>
.DeparmentDeleting_Parent {
  display: block;
  width: 100%;
  background-color: #fbf7e9;
  box-shadow: 0 3px 4px rgba(75, 81, 91, 0.15), 0 1px 2px rgba(0, 0, 0, 0.15);
  font-family: "Inter", "Roboto";
  font-size: 1.8rem;
  margin-top: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 1rem;
}
</style>
