<template>
  <div style="display: inline-block;margin-right: 1.5rem;color: #f2da07;width: 2rem;text-align: center;pointer-events: auto;cursor: default;">
    <img v-show="valide" style="width: 17px;" src="@/assets/media/circle_chk.svg" v-tooltip="'Valid'" alt="">
    <i v-show="!valide" class="far fa-clock" v-tooltip="'În proces de completare'"></i>
  </div>
</template>

<script>
  export default {
    props: {
      valide: {
        required: true,
        type: Boolean,
        default: false
      }
    }
  }
</script>