<template>
  <div
    class="
      Paap_Parent
      form-group-light
      showWhiteBgForMobile
      lateralMarginForMobile
    "
    style="
      margin-right: 1.75rem;
      flex: 1;
      display: flex;
      flex-direction: column;
    "
  >
    <div class="Paap_Main" style="flex: 1">
      <div>
        <inputs
          :genKey="genKey"
          :Data="value"
          :reference="Data.data"
          :selected="selected"
          :sel="select"
          :valide="valide"
          :syncSel="syncSelected"
          :readonly="isVersion || viewFinally"
          @getNeedFile="getNeedFile"
          @downloadFile="downloadFile"
          @addSection="createSection"
          @removeDynamic="deleteSection"
        />
      </div>
    </div>
    <div
      v-if="!isVersion"
      class="right"
      style="margin: 1rem 0 !important; display: flex"
    >
      <div style="display: flex; flex-grow: 1; align-items: center">
        <div style="flex-grow: 1">
          <div style="flex-grow: 1; text-transform: uppercase">
            <span>Pasul {{ viewFinally ? "2" : "1" }}/2</span>
          </div>
        </div>
        <div
          style="display: flex; align-items: center"
          v-tooltip="generatingPdf ? 'Generarea PDF este în proces' : ''"
        >
          <timer
            v-if="!viewFinally"
            :reloadCount="reloadTimerCount"
            :updating="updating"
            :updated="updated"
            :isReferenceFinished="isFinished"
            @sendUpdates="sendUpdates(true)"
          />
          <div
            v-else
            @click="$emit('viewPdf', referenceId, savePdfForFinished)"
            class="Button1"
            :class="{ Btn_disabled: generatingPdf }"
            style="
              min-width: 12rem;
              padding-top: 0.75rem;
              padding-bottom: 0.75rem;
              background-color: #f44336;
              margin-right: 1rem;
            "
          >
            <div class="icon">
              <i class="far fa-file-pdf" style="font-size: 1.8rem"></i>
            </div>
            <span class="Btn_content">
              Previzualizare PDF
              <span>
                <i v-show="generatingPdf" class="fas fa-sync fa-spin"></i>
              </span>
            </span>
          </div>
        </div>
      </div>
      <div style="margin-left: 1rem">
        <div
          v-if="viewFinally"
          @click="previousStep"
          class="Paap_btn Btn_secondary"
        >
          Înapoi
        </div>
        <div
          v-if="PERMISIONS.complete"
          @click="completeNeedReference"
          class="Paap_btn Btn_primary"
        >
          Salvează
        </div>
        <div
          v-else-if="
            !PERMISIONS.complete &&
              (Data.data.budgetClassification.length < 2 ||
                !Data.data.modalitateDeCentralizare)
          "
          @click="sendToCompleteNeedReference"
          class="Paap_btn Btn_primary"
          v-tooltip="messageSendToCompleteNeedReference()"
          :class="{
            Paap_btn_disabled: Data.data.needReferateStatus == 'completion',
          }"
        >
          Trimite spre completare
        </div>
        <div
          v-else
          @click="nextStep"
          class="Paap_btn Btn_primary"
          :class="{ allReadonly: !isOwner }"
        >
          {{
            viewFinally
              ? isFinished
                ? "Salvează și Trimite"
                : "Finalizează"
              : "Înainte"
          }}
        </div>
      </div>
    </div>
    <div v-else>
      <div
        style="
          display: flex;
          justify-content: space-between;
          font-family: 'Inter', 'Roboto';
        "
      >
        <span>Vedeți versiunea: {{ currentVersion + 1 }}</span>
        <span>{{ versionDate }}</span>
      </div>
      <template v-if="!canNotGeneratePdf">
        <div
          class="hr1"
          style="margin: 1rem 0; border-top-color: #dcdcdc"
        ></div>
        <div
          v-tooltip="generatingPdf === null ? 'Generarea PDF a eșuat.' : ''"
          style="display: inline-block"
        >
          <div
            @click="viewGenerated()"
            class="Button1"
            :class="{ Btn_disabled: generatingPdf || generatingPdf === null }"
            style="
              min-width: 12rem;
              padding-top: 0.75rem;
              padding-bottom: 0.75rem;
              background-color: #f44336;
              margin-right: 1rem;
            "
          >
            <div class="icon">
              <i class="far fa-file-pdf" style="font-size: 1.8rem"></i>
            </div>
            <span class="Btn_content">
              Previzualizare PDF
              <span>
                <i v-show="generatingPdf" class="fas fa-sync fa-spin"></i>
                <i
                  v-show="generatingPdf === null"
                  class="fas fa-times"
                  style="margin-left: 0.5rem"
                ></i>
              </span>
            </span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import reserveForEdit from "@/mixins/reserveForEdit";
import Inputs from "@/components/ReferateCompleting";
import ReferenceDraftTimer from "@/components/ReferenceDraftTimer";
import PermisionsMixin from "@/mixins/allPermMixin.js";

import {
  REFERENCE_RESERVATION,
  REFERENCE_AUTOSAVE,
  REFERENCE_MODIFY,
  NEEDS_DOWNLOAD_FILE,
  REFERENCE_GENERATE_PART_PDF,
  REFERENCE_ADD_DYNAMIC,
  REFERENCE_MODIFY_DYNAMIC,
  REFERENCE_DELETE_DYNAMIC,
  REFERENCE_SAVE_PDF,
  REFERENCE_GENERATE_PDF,
  REFERENCE_COMPLETE_CENTRALIZATION,
  REFERENCE_REQUEST_COMPLETE_CENTRALIZATION,
} from "@/api.js";
import { mapGetters, mapMutations } from "vuex";

const timeoutApi = window["apiTimeout"](5000, true);
const map = {
  createdAt: "referenceInfo.date",
  needReferateAchizitionDestination: "achizitionDesc.destination",
  needReferateAchizitionType: "achizitionDesc.type",
  fundamentareaNecesitatii: "fundamentNeed",
  necesitateaIdentificata: "identifiedNeed",
  //obiectiveleAutoritatiiContractante: "obectives1.ob1",
  obiectiveleProiectului: "obectives1.ob2",
  obiectiveleDinStrategia: "obectives1.ob3",
  beneficiiDupaSatisfacerea: "obectives1.ob4",
  justificareaAlegeriiModalitatiiDeSatisfacere: "justificationChoice",
  // 'descriereaObiectuluiSiPrecizareaStadiului': 'legislationContext.desc',
  descriereProdus: "docSuports.desc",
  cerinteFunctionaleAleProdusului: "docSuports.requirements",
  "calendar:signContract": "calendar.signContract",
  "calendar:signFirstContract": "calendar.signFirstContract",
  "calendar:release": "calendar.release",
  "calendar:fulfillmentOfObligations": "calendar.fulfillmentOfObligations",
  "calendar:finishContract": "calendar.finishContract",
  fondurileAlocateSatisfacereaNecesitatii: "perspectives.fonds",
  s11PerspectivaPeTermenLung: "longPerspective",
  s12FactoriCheie: "factors.sub1",
  s12ModalitateDeMasurare: "factors.sub2",
  s12IndicatorPerformanta: "factors.sub3",
  selectedAnnexes: "selectedAnnexes",
  "activeSections:S8": "activeSections.S8",
  "activeSections:S10": "activeSections.S10",
  "activeSections:S11": "activeSections.S11",
  "activeSections:S12": "activeSections.S12",
  budgetClassification: "budgetClassification",
  modalitateDeCentralizareType: "modalitateDeCentralizareType",
  modalitateDeCentralizare: "modalitateDeCentralizare",
  annualInstitutionObjectives: "obectives1.ob1",
};
const achizitionCategoryMap = {
  1: ["Produse", "produselor", "produs"],
  2: ["Servicii", "serviciilor", "serviciu"],
  3: ["Lucrări", "lucrărilor", "lucrare"],
};
var bougetsGetsCount = 0;
var generatingPdfCount = 0;

const saver = require("file-saver");
export default {
  name: "ReferateGenerate",
  mixins: [reserveForEdit, PermisionsMixin],
  components: {
    inputs: Inputs,
    timer: ReferenceDraftTimer,
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    Data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    genKey: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  watch: {
    value: {
      handler(x) {
        this.reloadTimerCount++;
      },
      deep: true,
    },
  },
  data() {
    return {
      updatingReference: false,
      updatingDynamicalSections: false,
      cachedFiles: [],
      viewFinally: false,
      reloadTimerCount: 0,
      syncSelected: 0,
      select: 0,
      selected: {
        7: false,
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        8: false,
        10: false,
        11: false,
        12: false,
        13: false,
        14: false,
      },
      valide: {},
      finallyInputs: null,
      generatingPdf: false,
      canNotGeneratePdf: false,
      pdfFile: null,
      saveDataForFinished: null,
      savePdfForFinished: null,
      initialFinished: null,
    };
  },
  computed: {
    ...mapGetters(["getCRS"]),

    isFinished() {
      const x = this.Data?.data?.needReferateStatus;
      return (
        this.isString(x) &&
        x !== "draft" &&
        x !== "completion" &&
        x !== "completed"
      );
    },
    referenceVersion() {
      return this.isFinished ? this.Data.data?.needReferateStatus ?? "" : "";
    },
    referenceId() {
      return this.Data.id;
    },
    isVersion() {
      return this.isObject(this.Data.versionData);
    },
    updating() {
      return this.updatingReference || this.updatingDynamicalSections;
    },
    updated() {
      return !this.isObjEmpty(this.checkUpdates);
    },
    conversingAnnexes() {
      return !!this.value.annexes.find((e) => e[0].generated === 1);
    },
    currentVersion() {
      return this.Data?.versionData?.versionNumber ?? null;
    },
    versionDate() {
      return this.Data?.versionData?.createdAt
        ? this.toDateAndTime(this.Data?.versionData?.createdAt)
        : null;
    },
    checkUpdates() {
      const changes = {};
      const w = this.Data?.data;
      const y = this.value;

      const t = (arr) => arr.filter((e) => e);
      const ignoreKeys = ["createdAt"];
      const transformRadioBox = (val, i) => {
        const map = {
          1: () =>
            ({
              one: "realizarea unui interes public",
              two: "asigurarea functionarii autoritatii contractante",
            }[val] || ""),
          2: () =>
            ({ one: "Contract", three: "Factura", two: "Acord-Cadru" }[val] ||
            ""),
        };
        return map[i] ? map[i]() : val;
      };

      if (!w) return {};

      Object.entries(map).forEach((e) => {
        const u = e[0].split(":")[0];
        const o = e[0].split(":")[1];
        if (ignoreKeys.includes(u)) return;
        let q = this.walker(y, e[1].split("."));
        if (u == "needReferateAchizitionDestination") {
          q = transformRadioBox(q, 1);
        }
        if (u == "needReferateAchizitionType") {
          q = transformRadioBox(q, 2);
        }
        if (u == "fondurileAlocateSatisfacereaNecesitatii") {
          q = q ? String(this.getLocaleNumber(q)) : "";
        }
        let x = w[u] || false;
        if (x && o) {
          x = x[o];
        }
        if (typeof q === "boolean") {
          if ((x || false) != (q || false)) {
            changes[u] = o ? { ...w[u], ...changes[u], [o]: q } : q;
          }
        } else if (this.isString(q) || q === null) {
          if (!x && q !== null) x = o ? {} : "";
          if ((x || false) !== (q || false)) {
            changes[u] = o ? { ...w[u], ...changes[u], [o]: q } : q;
          }
        } else if (Array.isArray(q)) {
          if (!Array.isArray(x)) {
            x = o ? {} : [];
          }
          q = t(q);
          if (
            (() => {
              const y = t(x);
              if (y.length != q.length) return true;
              for (let i = 0; i < y.length; i++) {
                if (y[i] !== q[i]) {
                  return true;
                }
              }
            })()
          ) {
            changes[e[0]] = o ? { ...w[u], [o]: q } : q;
          }
        } else if (this.isObject(q) && Number.isInteger(q.id)) {
          const j = q.id;

          if ((x.id && x.id != j) || x != j) {
            changes[u] = j;
          }
        } else if (this.isObject(q)) {
          if (w[u] !== q) changes[u] = q;
        }
      });

      return { ...changes, ...this.syncDinamicalSections };
    },
    isOwner() {
      const role = this.getUserRole(true);
      return this.Data.data.createdByUserRole == role;
    },
    syncDinamicalSections() {
      let { nrDinamicFieldEntity } = this.value;

      if (!Array.isArray(nrDinamicFieldEntity)) return [];

      const saved = this.Data?.data?.nrDinamicFieldEntity || [];

      const prepare = [];
      const trim = (str) => (this.isString(str) ? str.trim() : str);
      const keysMap = {
        short: "name",
        value: "continut",
      };

      if (
        saved.length &&
        nrDinamicFieldEntity.every(
          (e) =>
            e.data?._isDeleted !== true &&
            saved.find(
              (i) =>
                i.id === e.id &&
                i.name === e.short &&
                (i.continut || "") === (e.value || "")
            )
        )
      ) {
        return [];
      }

      nrDinamicFieldEntity.forEach((e) => {
        if (!e.data) return;
        const l = {
          updated: {},
        };

        if (e.data._isDeleted) {
          l["_isDeleted"] = true;
        } else {
          if (e.data._isNew) {
            l["_isNew"] = true;
          }

          for (let keys of Object.entries(keysMap)) {
            let x = e[keys[0]];
            if (x === undefined) continue;

            x = trim(x);

            l[keys[1]] = x;
            if (x !== trim(e.data[keys[1]] ?? "")) {
              l.updated[keys[1]] = x;
            }
          }
        }

        if (!this.isObjEmpty(l)) {
          l["data"] = e.data;
          l["id"] = e.id;
          l["_id"] = e._id;
          prepare.push(l);
        }
      });

      return prepare.length ? { nrDinamicFieldEntity: prepare } : {};
    },
  },
  methods: {
    ...mapMutations(["addWindow", "setReferenceIdForPreview"]),
    prepareFinally() {
      // this.finallyInputs = JSON.stringify(this.value);
    },
    messageSendToCompleteNeedReference() {
      if (this.Data.data.needReferateStatus != "completion") {
        return "Supervizorul de achiziție va stabili modalitate de centralizare și Responsabilul de buget va stabili clasificația bugetară.";
      }
      return "Referatul este în curs de completare";
    },
    viewGenerated() {
      if (this.pdfFile && !this.invalidBlob(this.pdfFile)) {
        this.setReferenceIdForPreview({
          file: this.pdfFile,
        });
      } else {
        this.$toastr.e("Fișierul nu a fost încărcat. Încercați mai târziu.");
      }
    },
    readFinally() {
      this.$emit(
        "value",
        this.JSONvalide(this.finallyInputs)
          ? JSON.parse(this.finallyInputs)
          : null
      );
      this.finallyInputs = null;
    },
    cancelRf() {
      this.$emit("cancelRf");
    },
    generateAnnexeFile(file) {
      if (!this.isObject(file)) return;
      file.generated = 1;
      REFERENCE_GENERATE_PART_PDF(this.referenceId, file.id, file.original)
        .then((res) => {
          if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
            file.generated = true;
          } else {
            file.selected = false;
            file.generated = 2;
          }
        })
        .catch((err) => {
          file.generated = false;
          this.$toastr.e(err);
        });
    },
    syncSelectedAnnexes() {
      this.value.selectedAnnexes = this.value.annexes
        .filter((e) => e[0].selected)
        .map((e) => {
          if (!e[0].generated || e[0].generated == 2) {
            this.generateAnnexeFile(e[0]);
          }
          return e[0].original;
        });
    },
    previousStep() {
      if (this.viewFinally) {
        this.simulateLoad(() => {
          // setTimeout(this.readFinally, 550);
          this.savePdfForFinished = null;
          this.viewFinally = false;
        });
      } else {
        this.simulateLoad(() => {
          this.$emit("cancelRf");
        });
      }
    },
    nextStep() {
      if (this.conversingAnnexes) {
        this.$toastr.w(
          "Așteptați până toate anexele selectate vor fi conversate!"
        );
        return;
      }
      if (this.updating) {
        this.$toastr.w("Așteptați până toate modificările vor fi salvate!");
        return;
      }
      const getSaved = () => {
        const saved = { ...(this.saveDataForFinished || {}) };

        if (!this.initialFinished) {
          this.$toastr.e("Anunțați administratorul.");
          return {};
        }
        const x = saved.nrDinamicFieldEntity?.filter(
          (e) => e?.data?._isDeleted !== true
        );

        if (
          this.compareArraysOfObj(
            this.initialFinished.nrDinamicFieldEntity,
            x,
            ["id", "name", "continut"]
          )
        ) {
          return {};
        }
        if (Array.isArray(x)) {
          saved.nrDinamicFieldEntity = x.map((e) => ({
            name: e.name,
            continut: e.continut,
          }));
        }
        return saved;
      };
      const nrDinamicFieldEntity = this.saveDataForFinished
        ?.nrDinamicFieldEntity;

      if (this.viewFinally) {
        if (this.generatingPdf) {
          this.$toastr.w("Așteptați până PDF-ul va fi generat!");
          return;
        }
        if (this.isFinished) {
          const sendData = getSaved();
          if (!this.isEdited(sendData, this.initialFinished)) {
            this.$toastr.w("Nu au fost găsite modificări.");
            return;
          }
          delete sendData["nrDinamicFieldEntity"];

          if (
            ["cancelled", "rejected", "approved"].includes(
              this.referenceVersion
            )
          ) {
            sendData["needReferateStatus"] = "signature_waiting";
          }

          const patchError = (msg) => {
            this.setLoad();
            this.$toastr.e(msg || "Editarea referatului a eșuat.");
          };
          const dynamicError = (msg) => {
            this.setLoad();
            this.$toastr.e(
              msg || "Editarea secțiunilor dinamice ale referatului a eșuat."
            );
          };
          const pdfError = (msg) => {
            this.setLoad();
            this.$toastr.e(msg || "Generarea PDF a referatului a eșuat.");
          };
          const editingSuccess = () => {
            this.setLoad();
            this.$emit("cancelRf", true);
            this.$toastr.s("Editarea referatului a decurs cu succes.");
          };

          this.setSafeLoad(12000);
          REFERENCE_MODIFY(
            this.referenceId,
            this.prepareDataForVerion(sendData)
          )
            .then((res) => {
              if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                if (nrDinamicFieldEntity) {
                  this.sendDynamic(nrDinamicFieldEntity, true)
                    .then(() => {
                      REFERENCE_SAVE_PDF(this.referenceId)
                        .then((res) => {
                          if (
                            this.checkHttpStatusCode(res?.meta?.HttpStatusCode)
                          ) {
                            editingSuccess();
                          } else {
                            pdfError();
                          }
                        })
                        .catch(pdfError);
                    })
                    .catch(dynamicError);
                } else {
                  editingSuccess();
                }
              } else {
                patchError();
              }
            })
            .catch(patchError);
        } else {
          this.$emit(
            "changeStatus",
            this.referenceId,
            "signature_waiting",
            (res) => {
              if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                this.$toastr.s(
                  "Completarea a fost finalizată cu succes.",
                  "Succes!"
                );
                this.$emit("cancelRf");
              } else if (this.isString(res.response)) {
                this.$toastr.e(res.response);
              } else {
                this.$toastr.e("Finalizarea referatului a decurs cu errori.");
              }
          
            }
          );
        }
      } else {
        this.simulateLoad(() => {
          if (
            Array.isArray(this.getCRS?.sections) &&
            !this.getCRS.sections.find(
              (e) => e.valide === false && e.active !== false
            )
          ) {
            this.syncUpdates(() => {
              this.viewFinally = true;
              this.prepareFinally();

              const err = (msg) => {
                this.previousStep();
                this.$toastr.e("Generarea PDF a eșuat, încercați mai târziu.");
                this.generatingPdf = false;
                if (msg) this.$toastr.e(msg);
              };
              generatingPdfCount++;
              this.generatingPdf = true;
              ((id) => {
                if (id != generatingPdfCount || !this.viewFinally) return;
                if (this.isFinished) {
                  REFERENCE_GENERATE_PDF(
                    this.prepareDataForVerion({
                      ...this.initialFinished,
                      ...getSaved(),
                    })
                  )
                    .then((blob) => {
                
                      if (!this.invalidBlob(blob)) {
                        this.savePdfForFinished = blob;
                        this.generatingPdf = false;
                      } else {
                        err();
                      }
                    })
                    .catch(err);
                } else {
                  REFERENCE_SAVE_PDF(this.referenceId)
                    .then((res) => {
                      if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                        this.generatingPdf = false;
                      } else {
                        err();
                      }
                    })
                    .catch(err);
                }
              })(generatingPdfCount);
            });
          } else {
            this.$toastr.w("Nu au fost completate informațiile obligatorii.");
          }
        });
      }
    },
    getNeedFile(file) {
      if (!Number.isInteger(file?.id)) return;
      const index = this.value.annexes.findIndex(
        (e) => e[0].original == file.original
      );

      this.addWindow(
        Object.assign(
          {
            fileList: this.value.annexes.map((e) => e[0].original),
            itemId: (() => {
              const prepare = {};
              this.value.annexes.forEach(
                (e) => (prepare[e[0].original] = e[0].id)
              );
              return prepare;
            })(),
            canDelete: false,
            modal: 13,
          },
          ~index ? { select: index } : {}
        )
      );
    },
    downloadFile(f) {
      if (!Array.isArray(f) || f[1]) return;
      if (this.cachedFiles.hasOwnProperty(f[0].original)) {
        saver.saveAs(this.cachedFiles[f[0].original], f[0].name);
        return;
      }
      f.splice(1, 1, true);
      NEEDS_DOWNLOAD_FILE(f[0].id, f[0].original)
        .then((file) => {
          f.splice(1, 1, false);
          if (this.invalidBlob(file)) {
            this.$toastr.Add({
              msg: `Au apărut errori la descărcarea fișierului: ${f[0].name}`,
              timeout: 10000,
              type: "error",
            });
            return;
          }
          saver.saveAs(file, f[0].name);
          this.cachedFiles[f[0].original] = file;
        })
        .catch((err) => {
          f.splice(1, 1, false);
          this.$toastr.e(err);
        });
    },
    syncUpdates(callback) {
      if (typeof callback != "function") return;
      let temporarWatcher;

      this.setSafeLoad(12000);
      if (this.isObjEmpty(this.checkUpdates)) {
        callback();
        this.setLoad();
      } else {
        this.sendUpdates(true);
        this.$nextTick(() => {
          temporarWatcher = this.$watch("updating", (x) => {
            if (!x) {
              temporarWatcher();
              callback();
              this.setLoad();
            }
          });
        });
      }
    },
    sendDynamic(dynamic, doNotSyncCache) {
      return new Promise((resolve) => {
        const h =
          dynamic ||
          this.saveDataForFinished?.nrDinamicFieldEntity ||
          this.syncDinamicalSections.nrDinamicFieldEntity;

        if (Array.isArray(h)) {
          const promises = [];
          const updatesFn = [];

          this.updatingDynamicalSections = true;

          h.forEach((e) => {
            promises.push(
              new Promise((resolve, reject) => {
                if (!this.isObject(e?.data)) {
                  reject("Data corrupt");
                  return;
                }
                const _id = e._id;
                const id = e.id;
                const find = this.value.nrDinamicFieldEntity.find(
                  (i) => i._id === _id
                );
                const prepare = e.updated || {};
                const syncModified = () => {
                  const find = this.Data.data.nrDinamicFieldEntity.find(
                    (i) => i.id === id
                  );
                  if (find) {
                    Object.entries(prepare).forEach((i) => {
                      this.$set(find, i[0], i[1]);
                    });
                  }
                };
                const errorEditing = () => {
                  this.$toastr.e("Anunțați administratorul.");
                  setTimeout(() => location.reload(), 500);
                  reject();
                };

                if (e.data._isDeleted) {
                  if (!Number.isInteger(id)) {
                    reject("Data corrupt");
                    return;
                  }
                  const error = (msg) => {
                    reject();
                    this.$toastr.e(
                      `Ștergerea secțiunii dinamice a eșuat.${
                        msg ? ` (${msg})` : ""
                      }`
                    );
                  };

                  REFERENCE_DELETE_DYNAMIC(id)
                    .then((res) => {
                      if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                        updatesFn.push(() => {
                          this.removeDynamic(_id);
                        });
                        resolve();
                      } else {
                        error();
                      }
                    })
                    .catch(error);
                } else if (e.data._isNew) {
                  const name = String(e.name).trim();
                  if (!name) {
                    reject("Data corrupt");
                    return;
                  }
                  if (!find?.data) {
                    errorEditing();
                    return;
                  }

                  REFERENCE_ADD_DYNAMIC({
                    ...prepare,
                    name,
                    needReferate: this.referenceId,
                  })
                    .then((res) => {
                      const newId = res?.data?.result?.id;

                      if (Number.isInteger(newId)) {
                        updatesFn.push(() => {
                          find.id = find.data.id = newId;
                          delete find._isNew;
                          delete find.data._isNew;
                          delete find.updated;

                          const r = {
                            id: newId,
                            ...prepare,
                          };

                          if (
                            Array.isArray(this.Data.data.nrDinamicFieldEntity)
                          ) {
                            this.Data.data.nrDinamicFieldEntity.splice(
                              this.Data.data.nrDinamicFieldEntity.length,
                              1,
                              r
                            );
                          } else {
                            this.Data.data.nrDinamicFieldEntity = [r];
                          }
                          syncModified();
                        });
                        resolve();
                      } else {
                        reject();
                        this.$toastr.e(
                          `Crearea secțiunii dinamice ”${name}” a eșuat.`
                        );
                      }
                    })
                    .catch((err) => {
                      reject();
                      this.$toastr.e(
                        `Crearea secțiunii dinamice a eșuat. (${err})`
                      );
                    });
                } else {
                  if (!Number.isInteger(id)) {
                    reject("Data corrupt");
                    return;
                  }

                  if (!this.isObjEmpty(prepare)) {
                    REFERENCE_MODIFY_DYNAMIC(id, prepare)
                      .then((res) => {
                        if (
                          this.checkHttpStatusCode(res?.meta?.HttpStatusCode)
                        ) {
                          updatesFn.push(syncModified);
                          resolve();
                        } else {
                          this.$toastr.e(
                            "Unele modificări pentru secțiunile dinamice au eșuat."
                          );
                          reject();
                        }
                      })
                      .catch((err) => {
                        reject(err);
                        this.$toastr.e(err);
                      });
                  } else {
                    reject();
                  }
                }
              })
            );
          });

          Promise.allSettled(promises).then((arr) => {
            if (!doNotSyncCache) updatesFn.forEach((e) => e());
            this.updatingDynamicalSections = false;

            if (arr.find((e) => e.status == "rejected")) {
              this.reloadTimerCount++;
              this.simulateLoad(() => {
                if (this.viewFinally) {
                  this.previousStep();
                }
              });
            }
            resolve();
          });
        }
      });
    },

    completeNeedReference() {
      const sendData = { ...this.checkUpdates };

      if (this.getUserRole() == "supervizor_achizitii") {
        const section = this.getCRS.sections.find(
          (e) => e.valide === false && e.id == 13
        );
        if (section) {
          this.$toastr.w("Nu au fost completate informațiile obligatorii.");
          return;
        }
      }

      if (this.getUserRole() == "responsabil_buget") {
        const section = this.getCRS.sections.find(
          (e) => e.valide === false && e.id == 14
        );
        if (section) {
          this.$toastr.w("Nu au fost completate informațiile obligatorii.");
          return;
        }
      }

      if (Object.keys(sendData).length) {
        this.updatingReference = true;

        REFERENCE_COMPLETE_CENTRALIZATION(this.referenceId, sendData)
          .then((res) => {
            if (!this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
              this.$toastr.e("Salvarea a eșuat");
            } else {
              this.Data.data = { ...this.Data.data, ...sendData };
              this.$toastr.s("Salvarea a fost realizată cu succes.");
            }

            this.updatingReference = false;
          })
          .catch((err) => {
            this.$toastr.e(err);
            this.updatingReference = false;
          });
      } else {
        this.$toastr.w("Nu s-au găsit modificări.");
      }
    },

    sendToCompleteNeedReference() {
      if (this.Data.data.needReferateStatus == "completion") return;

      const section = this.getCRS.sections.find(
        (e) =>
          e.valide === false && e.active !== false && e.id != 14 && e.id != 13
      );

      if (
        !section &&
        this.value.achizitionDesc.destination &&
        this.value.achizitionDesc.type
      ) {
        const sendData = { ...this.checkUpdates };

        this.updatingReference = true;

        REFERENCE_REQUEST_COMPLETE_CENTRALIZATION(this.referenceId, sendData)
          .then((res) => {
            if (!this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
              this.$toastr.e("Salvarea a eșuat");
            } else {
              this.Data.data = { ...this.Data.data, ...sendData };
              this.$toastr.s("Solicitarea a fost înregistrată cu success.");
              this.$router.push(`/${this.$route.params.navigation}/referate`);
            }
            this.updatingReference = false;
          })
          .catch((err) => {
            this.$toastr.e(err);
            this.updatingReference = false;
          });
      } else {
        this.$toastr.w("Nu au fost completate informațiile obligatorii.");
      }
    },

    sendUpdates(immediately) {
      if (this.isFinished) {
        this.updatingReference = true;
        const x = { ...this.checkUpdates };

        this.saveDataForFinished = this.deepClone(x);
        this.Data.data = { ...this.Data.data, ...x };

        for (let e of this.Data.data.nrDinamicFieldEntity || []) {
          if (e?._isDeleted) {
            this.removeDynamic(e._id);
          }
        }

        this.$nextTick(() =>
          this.$nextTick(() => (this.updatingReference = false))
        );
        return;
      }

      this.sendDynamic();

      if (this.updatingReference) return;

      const send = () => {
        const sendData = { ...this.checkUpdates };
        delete sendData["nrDinamicFieldEntity"];
        if (Object.keys(sendData).length) {
          const detectors = {
            selectedAnnexes: (val) => {
              if (!val?.length) {
                this.Data.data.convertedAnnexes = [];
                this.value.annexes.forEach((e) =>
                  e ? this.$set(e[0], "generated", false) : false
                );
              }
            },
          };

          this.updatingReference = true;

          Promise.all([
            REFERENCE_AUTOSAVE(this.referenceId, sendData)
              .then((res) => {
                if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                  this.Data.data = { ...this.Data.data, ...sendData };
                } else if (this.isString(res?.response)) {
                  this.$toastr.e(res.response);
                } else {
                  this.$toastr.e("Salvarea noilor date în draft a eșuat");
                }
                this.updatingReference = false;
              })
              .catch((err) => {
                this.$toastr.e(err);
                this.updatingReference = false;
              }),
          ]).then(() => {
            for (let e of Object.entries(detectors)) {
              if (sendData.hasOwnProperty(e[0])) {
                e[1](sendData[e[0]]);
              }
            }
          });
        }
      };

      if (immediately) send();
      else timeoutApi.call(this, send);
    },
    update(data) {
      this.$emit("input", data);
    },
    syncData() {
      const x = this.Data.data;
      const y = this.value;

      if (!x) return;
      const validator = (key, val) => {
        if (key == "createdAt") return this.toDateAndTime(val);
        if (key == "needReferateAchizitionDestination")
          return (
            {
              "realizarea unui interes public": "one",
              "asigurarea functionarii autoritatii contractante": "two",
            }[val] || ""
          );
        if (key == "needReferateAchizitionType")
          return (
            { Contract: "one", Factura: "three", "Acord-Cadru": "two" }[val] ||
            ""
          );
        return val || "";
      };
      const readDataForTable = (e, calculateTotalPrice) => {
        let cpv = "-";
        let totalPrice = "-";

        cpv = e.product?.cpvCategory?.cpvCode || "-";
        totalPrice = this.prepareLocaleNumber(
          calculateTotalPrice
            ? this._Multiply(e.qty, e.estimatedPrice)
            : e.sumNeed
        );

        const prepare = [
          e.product?.name || "-",
          this.readUserObject(e.createdByUser),
          this.toDateAndTime(e.createdAt),
          cpv,
          e.product?.measurementUnit?.shortName || "-",
          this.prepareLocaleNumber(+e.qty, true),
          this.prepareLocaleNumber(+e.estimatedPriceWoTva),
          this.prepareLocaleNumber(+e.estimatedPrice),
          this.prepareLocaleNumber(
            Number(e.estimatedPriceWoTva) * Number(e.qty)
          ),
          totalPrice,
        ];

        return prepare;
      };

      Object.entries(map).forEach((e) => {
        const b = e[1].split(".");
        const u = e[0].split(":")[0];
        const o = e[0].split(":")[1];

        let w = x[u];

        if (w && o) {
          w = w[o];
        }
        if (!w && w !== "") return;
        let q = this.walker(y, b);
        if (typeof q === "boolean") {
          this.walker(y, b, w);
        } else if (this.isString(q) || (o && this.isString(w)) || q == null) {
          this.walker(y, b, validator(u, w));
        } else if (Array.isArray(q) || (o && Array.isArray(w))) {
          this.walker(y, b, w ? [...w] : [""]);
        }
      });

      y.obectives1.ob1 = x.annualInstitutionObjectives.map((el) => ({
        ...el,
        label: el.institutionObjective.name,
      }));
      y.referenceInfo.user = this.readUserObject(x.createdByUser);
      y.referenceInfo.institution = x.institution?.name;
      y.referenceInfo.department = x.department?.name;

      const allowedAnnexe = [
        "png",
        "jpg",
        "jpeg",
        "doc",
        "docm",
        "docx",
        "docx",
        "ods",
        "pdf",
      ];
      const checkExtension = (filename) =>
        allowedAnnexe.includes(filename?.split(".").pop());
      const dominantNeedObject = this.isObject(x.dominantNeedOBJECT)
        ? x.dominantNeedOBJECT
        : x.need || null;

      if (dominantNeedObject) {
        const o = dominantNeedObject;

        y.docSuports.desc = o?.technicalDescription || "";
        if (o.product?.cpvCategory) {
          y.cpvType = o.product.cpvCategory.cpvType || 0;
          y.achizitionCategory =
            achizitionCategoryMap[o.product.cpvCategory.cpvType] || [];
          y.legislationContext.cpvCodes.push(
            Object.assign(o.product.cpvCategory, {
              _dominant: true,
              qty: Number.isInteger(o.qty) ? o.qty : "-",
              mu: o.product.measurementUnit?.shortName || "-",
              _label:
                { 1: "Produse", 2: "Servicii", 3: "Lucrări" }[
                  o.product.cpvCategory.cpvType
                ] || "-",
              _need: o.product.name,
            })
          );
        }
        if (Array.isArray(o.attachedDocuments)) {
          y.annexes = o.attachedDocuments.map((e) =>
            Object.assign(
              [
                {
                  id: o.id,
                  original: e,
                  valide: checkExtension(e),
                  selected: false,
                  name: this.getNeedFileName(e, true),
                  extension: e.split(".").pop(),
                  isPdf: this.getExtension(e) == "pdf",
                  generated: Array.isArray(x.convertedAnnexes)
                    ? !!x.convertedAnnexes.find(
                        (i) => e?.split("_")[0] === i.split("_")[0]
                      )
                    : false,
                },
                false,
              ],
              { _dominant: true }
            )
          );
        }
        y.achizitionDesc.table.data.push({
          ...readDataForTable(o, !o),
          _dominant: true,
        });
        y.s10.push({
          mod: o.modalitateaUtilizataDescriereaCaracteristicilor,
          notes: o.caietulDeSarciniTrimiteri || [],
          name: o.product?.name || "-",
          _dominant: true,
        });
        y.priceUnits.push(
          Object.assign(
            [
              parseInt(o.qty),
              o.product?.measurementUnit?.shortName,
              this.prepareLocaleNumber(o.estimatedPriceWoTva),
              this.prepareLocaleNumber(
                Number(o.estimatedPriceWoTva) * parseInt(o.qty)
              ),
              this.prepareLocaleNumber(parseFloat(o.estimatedPrice)),
              this.prepareLocaleNumber(o.sumNeed),
              o.product?.name ?? "-",
            ],
            { _dominant: true }
          )
        );
      }

      if (
        Array.isArray(x.complexIncludedNeeds) &&
        x.complexIncludedNeeds.length
      ) {
        const allNeeds = [dominantNeedObject, ...x.complexIncludedNeeds];

        y.legislationContext.cpvCodes = y.legislationContext.cpvCodes.concat(
          x.complexIncludedNeeds.map((e) => ({
            ...e.product?.cpvCategory,
            qty: Number.isInteger(e.qty) ? e.qty : "-",
            mu: e.product?.measurementUnit?.shortName || "-",
            _label:
              { 1: "Produse", 2: "Servicii", 3: "Lucrări" }[
                e.product?.cpvCategory?.cpvType
              ] || "-",
            _need: e.product.name,
          }))
        );

        y.s10 = y.s10.concat(
          x.complexIncludedNeeds.map((e) => ({
            mod: e.modalitateaUtilizataDescriereaCaracteristicilor,
            notes: e.caietulDeSarciniTrimiteri || [],
            name: e.product?.name || "-",
          }))
        );

        y.achizitionDesc.table.data = y.achizitionDesc.table.data.concat(
          x.complexIncludedNeeds.map(readDataForTable)
        );

        y.annexes = y.annexes.concat(
          (() => {
            const prepare = [];

            x.complexIncludedNeeds.forEach((e) => {
              if (Array.isArray(e.attachedDocuments)) {
                e.attachedDocuments.forEach((i) => {
                  prepare.push([
                    {
                      id: e.id,
                      original: i,
                      valide: checkExtension(i),
                      selected: false,
                      generated: Array.isArray(x.convertedAnnexes)
                        ? !!x.convertedAnnexes.find(
                            (e) => e?.split("_")[0] === i.split("_")[0]
                          )
                        : false,
                      isPdf: this.getExtension(i) == "pdf",
                      name: this.getNeedFileName(i, true),
                      extension: i.split(".").pop(),
                    },
                    false,
                  ]);
                });
              }
            });

            return prepare;
          })()
        );
        y.docSuports.desc = (() => {
          let prepare = "";

          allNeeds.forEach((e) => {
            if (e.technicalDescription) {
              prepare += `${e.technicalDescription} <hr>`;
            }
          });

          return prepare;
        })();
        y.priceUnits = y.priceUnits.concat(
          x.complexIncludedNeeds.map((e) =>
            Object.assign(
              [
                parseInt(e.qty),
                e.product?.measurementUnit?.shortName,
                this.prepareLocaleNumber(e.estimatedPriceWoTva),
                this.prepareLocaleNumber(
                  Number(e.estimatedPriceWoTva) * Number(e.qty)
                ),
                this.prepareLocaleNumber(parseFloat(e.estimatedPrice)),
                this.prepareLocaleNumber(e.sumNeed),
                e.product?.name ?? "-",
              ],
              e._dominant ? { _dominant: true } : {}
            )
          )
        );
      }
      if (Array.isArray(x.selectedAnnexes)) {
        let j = 0;
        for (let i = 0; i < y.annexes.length; i++) {
          if (x.selectedAnnexes.includes(y.annexes[i][0].original)) {
            const o = y.annexes[i][0];
            o.selected = true;
            j++;
          }
          if (j > x.selectedAnnexes.length) break;
        }
      }
      if (Array.isArray(x.nrDinamicFieldEntity)) {
        x.nrDinamicFieldEntity.forEach((e) => this.addSection(e.name, e));
      }

      if (this.isObject(x.sursaDeFinantareSiPozitiaBuget)) {
        y.perspectives.sources = x.sursaDeFinantareSiPozitiaBuget;
      }
    },
    getLatestIndexOfDynamicalSection() {
      return String(
        Math.max(
          13,
          this.getMaxOfObjects(this.value.nrDinamicFieldEntity, "_id") + 1 || 0
        )
      );
    },
    createSection(name) {
      if (!name) return;

      this.addSection(name, {
        _isNew: true,
      });
    },
    deleteSection(data) {
      if (!Number.isInteger(data?.id) || !this.isObject(data.data)) return;

      if (!this.isFinished && data.data?._isNew) {
        this.removeDynamic(data._id);
      } else {
        this.$set(data.data, "_isDeleted", true);
      }
    },
    addSection(title, data = { active: true }) {
      if (!this.isString(title)) return;
      const x = this.getLatestIndexOfDynamicalSection();
      this.$set(this.selected, x, false);
      this.value.nrDinamicFieldEntity.push({
        short: title,
        id: data?.id ?? this.makeid(6),
        valide: false,
        dynamical: true,
        value: data.continut || "",
        _id: +x,
        data,
      });

      this.syncSelected++;

      this.select = +x;
    },
    removeDynamic(id) {
      delete this.selected[id];
      this.value.nrDinamicFieldEntity = this.value.nrDinamicFieldEntity.filter(
        (e) => e._id != id
      );
      this.syncSelected++;
    },
    prepareDataForVerion(data) {
      if (!this.isObject(data)) return null;
      data = { ...data };

      const preparingIdMap = ["need", "institution", "department"];

      for (let key of preparingIdMap) {
        if (this.isObject(data[key])) data[key] = data[key].id ?? null;
      }

      return data;
    },
    generatePdfOfVersion() {
      const data = this.prepareDataForVerion(this.Data.data);

      this.generatingPdf = true;

      const error = (msg) => {
        this.$toastr.e(msg || "Generarea PDF a eșuat.");
        this.generatingPdf = null;
      };

      REFERENCE_GENERATE_PDF(data)
        .then((file) => {
          if (this.invalidBlob(file)) {
            error();
          } else {
            this.generatingPdf = false;
            this.pdfFile = file;
          }
        })
        .catch(error);
    },
  },
  created() {
    this.initReserve(
      REFERENCE_RESERVATION.bind(null, false, this.referenceId),
      REFERENCE_RESERVATION.bind(null, true, this.referenceId),
      () => {
        this.$emit("cancelRf");
      },
      "Referatul"
    );

    this.initAllPermisions();

    if (this.isVersion) {
      if (this.isFinished) {
        this.generatePdfOfVersion();
      } else {
        this.canNotGeneratePdf = true;
      }
    } else if (this.isFinished) {
      this.initialFinished = this.deepClone(this.Data.data);
    }

    this.setLoad();

    this.syncData();

    this.$nextTick(() => {
      this.$watch("value.annexes", {
        handler() {
          this.syncSelectedAnnexes();
        },
        deep: true,
      });
    });
  },
};
</script>
