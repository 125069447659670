export default {
  data() {
    return {
      PERMISIONS: {
        list: false,
        create: false,
        viewDetails: false,
        viewHistory: false,
    /*     approve: false,
        reject: false, */
        sendReview: false,
        edit: false,
        delete: false,
        generatePdf: false,
        complete: false,
      }
    }
  },
  created() {
    const statuses = ['pending','approved','rejected','review']
    const baseRule = 'referat-necesitate'

    this.initPermision(`${baseRule}.get`,'list',statuses)
    this.initPermision(`${baseRule}.view_details`,'viewDetails',statuses)
    this.initPermision(`${baseRule}.view_history`,'viewHistory',statuses)
    this.initPermision(`${baseRule}.post`,'create',statuses)
    this.initPermision(`${baseRule}.patch`,'edit',statuses)
    this.initPermision(`${baseRule}.delete`,'delete',statuses)
    this.initPermision(`${baseRule}.send_review`,'sendReview',statuses)
 /*    this.initPermision(`${baseRule}.approve`,'approve',statuses)
    this.initPermision(`${baseRule}.reject`,'reject',statuses) */
    this.initPermision(`${baseRule}.generate_pdf`,'generatePdf',statuses)
    this.initPermision(`${baseRule}.complete`,'complete',statuses)
  }
}